<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <strong>{{ $t('Zaduzenja') }}:</strong> {{ ukupnoZaduzenje }} <strong>{{ $t('Razduzenja') }}:</strong> {{ ukupnoRazduzenje }} <strong>{{ $t('Stanje') }}:</strong> {{ stanje }}
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Finansije')">
        <div>
          <!-- Table Top -->
          <b-row
            class="p-1"
          >
            <!-- Per Page -->
            <b-col
              lg="4"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-datepicker
                id="filter_pocetni_datum"
                v-model="filterPocetniDatum"
                :placeholder="$t('PocetniDatum')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-col>
            <b-col
              lg="4"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-datepicker
                id="filter_krajnji_datum"
                v-model="filterKrajnjiDatum"
                :placeholder="$t('KrajnjiDatum')"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="this.$i18n.locale"
                :hide-header="hideHeader"
                today-button
                reset-button
                close-button
                show-decade-nav
                :label-today-button="$t('Danas')"
                :label-reset-button="$t('Ponisti')"
                :label-close-button="$t('Zatvori')"
                :label-prev-year="$t('PrethodnaGodina')"
                :label-prev-month="$t('PrethodniMesec')"
                :label-next-year="$t('SledecaGodina')"
                :label-next-month="$t('SledeciMesec')"
                :label-current-month="$t('TekuciMesec')"
                :label-prev-decade="$t('MinusDesetGodina')"
                :label-next-decade="$t('PlusDesetGodina')"
                label-help=""
                no-flip
              />
            </b-col>
            <b-col
              cols="4"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                variant="info"
                @click="pokupiListu()"
              >
                {{ $t("Filter") }}
              </b-button>
            </b-col>
          </b-row>

        </div>
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <router-link :to="{ name: 'autoskola-kandidat-finansije', params: {id: data.item.kandidat_id} }">
                <b-button
                  variant="info"
                  size="sm"
                >
                  {{ $t("StranaFinansije") }}
                </b-button>
              </router-link>
              <b-button
                variant="danger"
                size="sm"
                @click="modalBrisanje(data.item.id, data.item.ugovor)"
              >
                {{ $t("Obrisi") }}
              </b-button>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  {{ $t("SakrijDetalje") }}
                </b-button>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
            <template
              v-slot:custom-foot
            >
              <tr
                class="p-3 mb-2 bg-success text-dark"
              >
                <td
                  colspan="3"
                >
                  &nbsp;
                </td>
                <td
                  class="text-right"
                >
                  {{ ukupnoZaduzenje }}
                </td>
                <td
                  class="text-right"
                >
                  {{ ukupnoRazduzenje }}
                </td>
                <td
                  colspan="2"
                >
                  {{ stanje }}
                </td>
              </tr>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaRezultata') }}</span>
            </div>
          </b-alert>
          <b-container
            v-if="Object.entries(items).length"
            fluid="sm"
          >
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="linkovi.last_page"
              use-router
              align="center"
              class="mt-1"
            />
          </b-container>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-brisanje-finansija"
      ref="modal-brisanje-finansija"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Obrisi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('ObrisiFinansije')"
      size="lg"
      @ok="obrisiFinansije"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigutniDaZeliteDaObriseteFinansije:') }}
        </p>
        <p
          v-if="podaciFinansijeBrisanje.ugovor !== null"
        >
          {{ $t('FinansijeSuVezaneZaUgovorOdDana') }} {{ typeof podaciFinansijeBrisanje.ugovor !== 'undefined' ? podaciFinansijeBrisanje.ugovor.datum : '' }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BCard,
  BModal,
  VBModal,
  BPaginationNav,
  BContainer,
  BRow,
  BCol,
  BFormDatepicker,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BModal,
    BCard,
    BPaginationNav,
    BContainer,
    BRow,
    BCol,
    BFormDatepicker,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 1,
      rows: 100,
      fields: [
        // { key: 'detalji', label: '' },
        { key: 'idkandidata', label: this.$i18n.t('IDKandidata') },
        { key: 'imeprezime_kandidata', label: this.$i18n.t('ImePrezime') },
        { key: 'zaduzenje', label: this.$i18n.t('Zaduzenje'), class: 'text-right' },
        { key: 'razduzenje', label: this.$i18n.t('Razduzenje'), class: 'text-right' },
        { key: 'duguje', label: this.$i18n.t('Duguje') },
        { key: 'telefon', label: this.$i18n.t('Telefon') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      modalZaduzenjeTitle: '',
      hideHeader: true,
      dataNoviZapis: { datum: moment(new Date()).format('YYYY-MM-DD') },
      formErrors: [],
      notificationVariant: 'success',
      notificationTitle: this.$i18n.t('Uspesno'),
      notificationMessage: this.$i18n.t('NoviZapisJeUspesnoDodat'),
      artikli: [],
      poreskaStopa: '',
      poreskeStope: ['А', 'Ђ', 'Г', 'Е'],
      nacinPlacanja: 0,
      racunZaStampu: '',
      tipRacuna: 'Normal',
      podaciFinansijeBrisanje: {},
      linkovi: '',
      ukupnoZaduzenje: 0,
      ukupnoRazduzenje: 0,
      stanje: 0,
      filterPocetniDatum: '',
      filterKrajnjiDatum: '',
    }
  },
  watch: {
    $route() {
      this.pokupiListu()
    },
    'dataNoviZapis.artikal': {
      handler(artikal) {
        this.popuniDataNoviZapisArtikal(artikal)
      },
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    pokupiListu() {
      const page = typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
      this.$http.get(`/autoskole-administratori/finansije?page=${page}&pocetniDatum=${this.filterPocetniDatum}&krajnjiDatum=${this.filterKrajnjiDatum}`).then(response => {
        this.items = response.data.finansije
        this.linkovi = response.data.linkovi
        this.kandidatData = response.data.kandidat
        this.ukupnoZaduzenje = response.data.ukupnaZaduzenja
        this.ukupnoRazduzenje = response.data.ukupnaRazduzenja
        this.stanje = response.data.stanje
      })
    },
    priznanica(id) {
      this.$http.get(`/autoskole-administratori/kandidat/finansije/priznanica/${id}`, { responseType: 'arraybuffer' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Priznanica.pdf'
        link.click()
        this.$vs.loading.close()
      })
    },
    modalBrisanje(id, ugovor) {
      this.podaciFinansijeBrisanje = {
        id,
        ugovor,
      }
      this.$bvModal.show('modal-brisanje-finansija')
    },
    obrisiFinansije() {
      this.$http.delete(`/autoskole-administratori/kandidat/finansije/${this.podaciFinansijeBrisanje.id}`).then(() => {
        this.$bvModal.hide('modal-brisanje-finansija')
        this.pokupiListu()
      })
    },
  },
}
</script>
